import React from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import { isFutureSet } from "@/game-tft/constants.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import { cssTierIcon } from "@/game-tft/OverviewChampions.style.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import {
  useAugmentsByParams,
  useLocalizedAugments,
} from "@/game-tft/use-augments.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { getLocale, getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const columns = [
  {
    label: ["common:navigation.augment", "Augment"],
    dataKey: "name",
    align: "left",
    primary: true,
  },
  {
    label: ["lol:tier", "Tier"],
    dataKey: "tier",
  },
  {
    label: ["tft:pickRate", "Pick Rate"],
    dataKey: "pick_rate",
  },
  {
    label: ["common:avgPlace", "Avg. Place"],
    dataKey: "avg_placement",
  },
  {
    label: ["tft:topFour", "Top 4"],
    dataKey: "top_4_percent",
  },
];

export default function HomeStatisticsTile() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const state = useSnapshot(readState);
  const set = useSetByParam(true);
  const augmentsFilters = getFilters(
    searchParams,
    "augments",
    isFutureSet(set),
  );
  const augmentsStats = state.tft.stats.augments[augmentsFilters]?.[set];
  const augments = state.tft[set]?.augments;
  const champions = state.tft[set]?.champions;
  const localizedAugments = useLocalizedAugments(set);
  const type = "ALL";
  const items = useAugmentsByParams({
    augments,
    augmentsStats,
    champions,
    localizedAugments,
    set,
    type,
  })?.sort((a, b) => a.tier - b.tier);
  const patch = state.tft.latestPatch?.patch;

  const cols = columns.map((col) => {
    return {
      display: t(col.label[0], col.label[1]),
      align: col.align,
      primary: col.primary,
      isStat: col.isStat,
    };
  });
  const rows = items.map((item) => {
    return columns.map((col) => {
      return {
        display: colRenderer({
          dataKey: col.dataKey,
          rowData: item,
          set: set,
          t: t,
        }),
        value: item[col.dataKey],
        className: col.className,
      };
    });
  });

  return (
    <GameGuideTileLg
      title={["tft:augments", "Augments"]}
      description={[
        "home:guides.tft.augments.description",
        "Stay ahead of the TFT meta with Blitz.gg. Explore the top Augments in the meta.",
      ]}
      buttonText={[
        "common:navigation.viewAugmentStatistics",
        "View Augment Stats",
      ]}
      href={"/tft/augments/stats"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="main-col">
            {patch
              ? t("common:patchNumber", "Patch {{patchNumber}}", {
                  patchNumber: patch,
                })
              : cols[0].display}
          </div>
          <div className="stat">{cols[1].display}</div>
          <div className="stat">{cols[2].display}</div>
          <div className="stat">{cols[3].display}</div>
          <div className="stat">{cols[4].display}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={6}>
          {(rows || []).map((row, i) => {
            return (
              <GameGuideTable.Row key={i} className={augmentRows()}>
                <div className="main-col">{rows[i][0].display}</div>
                {rows[i].slice(1).map((item, y) => (
                  <div className="stat" key={i + y}>
                    {item.display}
                  </div>
                ))}
              </GameGuideTable.Row>
            );
          })}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

// Renderers
const colRenderer = ({ dataKey, rowData, set, t }) => {
  const locale = getLocale();
  const AugmentNameContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    text-align: left;
  `;
  const WinRate = styled("span")`
    color: ${(props) => props.color || "var(--shade0)"};
  `;
  const MainColText = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;

  if (dataKey === "name") {
    const AugmentName = ({ name, augmentKey, showName }) => (
      <AugmentTooltip augment={augmentKey} style={{ maxWidth: "100%" }}>
        <AugmentNameContainer className="type-body2">
          <img
            src={StaticTFT.getAugmentImage(augmentKey, set)}
            alt={name}
            width={32}
            height={32}
          />
          <div className={MainColText}>
            {showName &&
              name +
                (augmentKey.includes("_Legend_")
                  ? ` - ${t("tft:legend", "Legend")}`
                  : "")}
          </div>
        </AugmentNameContainer>
      </AugmentTooltip>
    );
    return (
      <AugmentName
        champKey={rowData.champKey}
        cost={rowData.cost}
        name={rowData.name}
        augmentKey={rowData.key}
        isHero={rowData.isHero}
        key={rowData.key}
        showName={true}
      />
    );
  }

  if (dataKey === "tier") {
    const TierIcon = getTierIcon(rowData.tier);
    return TierIcon ? <TierIcon className={cssTierIcon()} /> : null;
  }

  if (dataKey === "pick_rate" && typeof rowData.pick_rate === "number") {
    const avgPickRate = 0.12;
    const digits = 1;
    const rounding = 1000;
    return (
      <WinRate color={getWinRateColor(rowData.pick_rate, avgPickRate, false)}>
        {getLocaleString(Math.round(rowData.pick_rate * rounding) / rounding, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
          style: "percent",
        })}
      </WinRate>
    );
  }

  if (
    dataKey === "avg_placement" &&
    typeof rowData.avg_placement === "number"
  ) {
    return (
      <WinRate
        color={
          !rowData.key?.includes("_Legend_")
            ? getWinRateColor(rowData.avg_placement, 4, true)
            : "var(--shade1)"
        }
      >
        {rowData.key?.includes("_Legend_")
          ? "-"
          : rowData.avg_placement.toLocaleString(locale, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}
      </WinRate>
    );
  }
  if (
    dataKey === "top_4_percent" &&
    typeof rowData.top_4_percent === "number"
  ) {
    return (
      <div className="type-body2">
        {rowData.key?.includes("_Legend_")
          ? "-"
          : t("lol:percent", "{{number}}%", {
              number: (rowData.top_4_percent * 100).toLocaleString(locale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            })}
      </div>
    );
  }
  if (
    dataKey === "top_1_percent" &&
    typeof rowData.top_1_percent === "number"
  ) {
    return (
      <div className="type-body2">
        {rowData.key?.includes("_Legend_")
          ? "-"
          : getLocaleString(rowData.top_1_percent, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              style: "percent",
            })}
      </div>
    );
  }

  return null;
};

const Table = () => css`
  .main-col {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
    max-width: 40%;
    overflow: hidden;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;

    @container home-table (inline-size <= 400px) {
      width: 20%;
    }
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
  @container home-table (inline-size <= 400px) {
    .table-header .stat:last-child,
    .table-body .stat:last-child {
      display: none;
    }
  }
`;

const augmentRows = () => css`
  height: 53px !important;
`;
